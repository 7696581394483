<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item active">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a href="javascript:;">Dashboard</a>
      </li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->

    <h1 class="page-header">Dashboard</h1>
    <div class="row">
      <div class="col-12 col-md-2">
        <select
          v-model="filter.year"
          v-on:change="
            fetchData();
            filter.month = '';
          "
          class="form-control mb-2"
        >
          <option value="">Pilih Tahun</option>
          <option value="2024">2024</option>
        </select>
      </div>
      <div class="col-12 col-md-2">
        <select
          v-if="filter.year"
          v-model="filter.month"
          v-on:change="fetchData()"
          class="form-control mb-2"
        >
          <option value="">Pilih Bulan</option>
          <option value="01">Januari</option>
          <option value="02">Februari</option>
          <option value="03">Maret</option>
          <option value="04">April</option>
          <option value="05">Mei</option>
          <option value="06">Juni</option>
          <option value="07">Juli</option>
          <option value="08">Agustus</option>
          <option value="09">September</option>
          <option value="10">Oktober</option>
          <option value="11">November</option>
          <option value="12">Desember</option>
          <option value="13">Semua Bulan</option>
        </select>
      </div>
      <div class="col-10 col-md-2">
        <select
          v-if="filter.month && filter.month !== '13'"
          v-model="filter.week"
          v-on:change="fetchData()"
          class="form-control mb-2"
        >
          <option value="">Pilih Minggu</option>
          <option value="1">Minggu 1</option>
          <option value="2">Minggu 2</option>
          <option value="3">Minggu 3</option>
          <option value="4">Minggu 4</option>
        </select>
      </div>
      <div class="col-2 col-md-6">
        <button
          class="pull-right btn btn-icon btn-light"
          @click="fetchData()"
          style="height: 2.1rem; width: 2.1rem"
        >
          <i
            class="fa fa-sync"
            :class="{
              'fa-spin': isLoading,
            }"
          ></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <!-- begin col-3 -->
      <div class="col-md-3 col-sm-6">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon"><i class="fa fa-ticket-alt"></i></div>
          <div class="stats-info">
            <h4>Total Ticket</h4>
            <p>{{ j_tiket }}</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;"
              >View Detail <i class="fa fa-arrow-circle-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-md-3 col-sm-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon">
            <i class="fa fa-clipboard-list"></i>
          </div>
          <div class="stats-info">
            <h4>Total Ticket Open</h4>
            <p>{{ j_open }}</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;"
              >View Detail <i class="fa fa-arrow-circle-o-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-md-3 col-sm-6">
        <div class="widget widget-stats bg-purple">
          <div class="stats-icon"><i class="fa fa-clipboard-check"></i></div>
          <div class="stats-info">
            <h4>Total Ticket Close</h4>
            <p>{{ j_close }}</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;"
              >View Detail <i class="fa fa-arrow-circle-o-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-md-3 col-sm-6">
        <div class="widget widget-stats bg-red">
          <div class="stats-icon"><i class="fa fa-clock"></i></div>
          <div class="stats-info">
            <h4>Current Time</h4>
            <p v-html="dataJam"></p>
          </div>
          <div class="stats-link">
            <a href="javascript:;"
              >View Detail <i class="fa fa-arrow-circle-o-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Ticket By Type Ticket</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByTypeTicket.data"
              :options="grafikByTypeTicket.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">
              Grafik Data Preventive Plan vs Actual
            </h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByPlanActual.data"
              :options="grafikByPlanActual.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Ticket By Issue</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByIssue.data"
              :options="grafikByIssue.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Ticket By Problem</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByProblem.data"
              :options="grafikByProblem.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Visit By Area</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByArea.data"
              :options="grafikByArea.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Visit By Location</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikByLocation.data"
              :options="grafikByLocation.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Grafik Visit By Sub Location</h3>
          </div>
          <div class="panel-body">
            <bar-chart
              v-if="!isLoading"
              :data="grafikBySubLocation.data"
              :options="grafikBySubLocation.options"
              class="height-md"
            ></bar-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import userData from "../plugins/userData";
import BarChart from "../components/vue-chartjs/BarChart";
import grafikByTypeTicket from "@/assets/grafik/by_type.json";
import grafikByPlanActual from "@/assets/grafik/by_plan.json";
import grafikByProblem from "@/assets/grafik/by_problem.json";
import grafikByIssue from "@/assets/grafik/by_issue.json";
import grafikByArea from "@/assets/grafik/by_area.json";
import grafikByLocation from "@/assets/grafik/by_location.json";
import grafikBySubLocation from "@/assets/grafik/by_sublocation.json";

export default {
  mixins: [userData],
  components: {
    BarChart,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        year: "",
        month: "",
        week: "",
      },
      dataJam: "00:00:00",
      j_tiket: 0,
      j_open: 0,
      j_close: 0,
      j_time: 0,
      data_grafik_by_type_ticket: [],
      data_grafik_by_problem_ticket: [],
      data_grafik_by_issue_ticket: [],
      data_grafik_by_plan_actual: [],
      data_grafik_by_area: [],
      data_grafik_by_location: [],
      data_grafik_by_sublocation: [],
      grafikByPlanActual: grafikByPlanActual,
      grafikByProblem: grafikByProblem,
      grafikByIssue: grafikByIssue,
      grafikByTypeTicket: grafikByTypeTicket,
      grafikByArea: grafikByArea,
      grafikByLocation: grafikByLocation,
      grafikBySubLocation: grafikBySubLocation,
      error: "",
    };
  },
  mounted() {
    this.jamDigital();
    setInterval(() => {
      this.jamDigital();
    }, 1000);
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("http://localhost/api-crm-dea/v2/dashboard/", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
            week: this.filter.week,
          },
        })
        .then((response) => {
          this.j_tiket = response.data.total_ticket;
          this.j_open = response.data.open_ticket;
          this.j_close = response.data.close_ticket;
          this.data_grafik_by_type_ticket = response.data.type_ticket;
          this.data_grafik_by_problem_ticket = response.data.problem_ticket;
          this.data_grafik_by_issue_ticket = response.data.issue_ticket;
          this.data_grafik_by_plan_actual = response.data.plan_ticket;
          this.data_grafik_by_area = response.data.area_ticket;
          this.data_grafik_by_location = response.data.location_ticket;
          this.data_grafik_by_sublocation = response.data.sublocation_ticket;
          this.isLoading = false;
          this.setgrafikByProblem();
          this.setgrafikByIssue();
          this.setBargrafikByTypeTicket();
          this.setBargrafikByPlanActual();
          this.setgrafikByArea();
          this.setgrafikByLocation();
          this.setgrafikBySubLocation();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
          this.isLoading = false;
        });
    },
    setgrafikBySubLocation() {
      const jsonData = this.data_grafik_by_sublocation;
      const labels = jsonData.map((item) => item.sublocation_name);
      const data = jsonData.map((item) => parseInt(item.visit, 10));
      this.grafikBySubLocation.data.labels = labels;
      this.grafikBySubLocation.data.datasets[0].data = data;
    },
    setgrafikByLocation() {
      const jsonData = this.data_grafik_by_location;
      const labels = jsonData.map((item) => item.location_name);
      const data = jsonData.map((item) => parseInt(item.visit, 10));
      this.grafikByLocation.data.labels = labels;
      this.grafikByLocation.data.datasets[0].data = data;
    },
    setgrafikByArea() {
      const jsonData = this.data_grafik_by_area;
      const labels = jsonData.map((item) => item.area_name);
      const data = jsonData.map((item) => parseInt(item.visit, 10));
      this.grafikByArea.data.labels = labels;
      this.grafikByArea.data.datasets[0].data = data;
    },
    setBargrafikByPlanActual() {
      const jsonData = this.data_grafik_by_plan_actual;
      const labels = jsonData.map((item) => item.nama_area);
      const planData = jsonData.map((item) => parseInt(item.plan, 10));
      const actualData = jsonData.map((item) => parseInt(item.actual, 10));

      this.grafikByPlanActual.data.labels = labels;
      this.grafikByPlanActual.data.datasets = [
        {
          label: "Plan",
          backgroundColor: "rgba(255,91,87, 0.6)",
          borderColor: "#FF5B57",
          data: planData,
        },
        {
          label: "Actual",
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "#93C3D2",
          data: actualData,
        },
      ];
    },
    setgrafikByIssue() {
      const jsonData = this.data_grafik_by_issue_ticket;
      const labels = jsonData.map((item) => item.issue);
      const data = jsonData.map((item) => parseInt(item.j_tiket, 10));
      this.grafikByIssue.data.labels = labels;
      this.grafikByIssue.data.datasets[0].data = data;
    },
    setgrafikByProblem() {
      const jsonData = this.data_grafik_by_problem_ticket;
      const labels = jsonData.map((item) => item.cat_ticket);
      const data = jsonData.map((item) => parseInt(item.j_tiket, 10));
      this.grafikByProblem.data.labels = labels;
      this.grafikByProblem.data.datasets[0].data = data;
    },
    setBargrafikByTypeTicket() {
      const jsonData = this.data_grafik_by_type_ticket;
      this.grafikByTypeTicket.data.labels = [
        "Preventive",
        "Corrective",
        "Task List",
      ];
      this.grafikByTypeTicket.data.datasets[0].data = [
        parseInt(jsonData[0].preventive),
        parseInt(jsonData[0].corrective),
        parseInt(jsonData[0].task_list),
      ];
    },
    jamDigital() {
      var waktu = new Date();
      var sh = waktu.getHours() + "";
      var sm = waktu.getMinutes() + "";
      var ss = waktu.getSeconds() + "";
      this.dataJam =
        (sh.length == 1 ? "0" + sh : sh) +
        ":" +
        (sm.length == 1 ? "0" + sm : sm) +
        ":" +
        (ss.length == 1 ? "0" + ss : ss);
    },
  },
};
</script>
